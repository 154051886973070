import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const EngineeringPosition = () => {
  return (
    <div className="my-8 px-8">
      <h3 className="text-2xl font-light lg:text-4xl lg:mb-16 lg:font-normal">
        Software Engineering
      </h3>

      <div className="px-8 lg:hidden">
        <StaticImage
          className="mt-8"
          src="../images/upgrade-manual-processes.png"
          alt="Software engieering"
        />
      </div>

      <div className="font-light text-lg">
        <p className="mt-6">
          We are looking for highly motivated and skilled Software Engineers who are looking to work on multiple projects in a dynamic environment.
        </p>

        <h4 className="text-[#5B23A4] font-bold mt-12">
          What you'll do:
        </h4>

        <p className="mt-6">
          Participate in client meetings to better understand the specific applications or functionalities they need to take their business to the next level. Help create technical solutions for our clients.
        </p>

        <p className="mt-6">
          Spend time developing and maintaining one or more components of custom web applications; designing and creating new application features, taking ownership of engineering initiatives, and working independently in an established codebase.
        </p>

        <div>
          <div className="hidden lg:w-1/3 lg:ml-6 lg:inline lg:float-right">
            <StaticImage
              src="../images/upgrade-manual-processes.png"
              alt="Software engieering"
            />
          </div>

          <h5 className="font-semibold mt-6">
            Initially, you'll help develop:
          </h5>

          <ul className="mt-6 list-disc">
            <li className="ml-8">
              Google Cloud Functions to support custom workflows
            </li>
            <li className="ml-8">
              UI components using React
            </li>
            <li className="ml-8">
              Custom integrations between customers and data sources
            </li>
          </ul>

          <h5 className="font-semibold mt-6">
            Also, you'll be challenged to:
          </h5>

          <ul className="mt-6 list-disc">
            <li className="ml-8">
              Find opportunities to improve our engineering processes
            </li>
            <li className="ml-8">
              Tackle challenges creatively and critically evaluate your work
            </li>
          </ul>

        </div>

        <h4 className="text-[#5B23A4] font-bold mt-12">
          What we are looking for:
        </h4>

        <ul className="mt-6 list-disc">
          <li className="ml-8">
            Fluency in React (or experience with Angular, Vue, or similar), Python, Javascript/Typescript and HTML/CSS
          </li>
          <li className="ml-8">
            Proficiency with NoSQL databases such as Firestore, MongoDB, etc
          </li>
          <li className="ml-8">
            Deep understanding of modern cloud technologies like Google Cloud or AWS
          </li>
          <li className="ml-8">
            Attention to creating module, secure, and well-tested code
          </li>
          <li className="ml-8">
            Experience building and shipping end-to-end features to a sizable user base
          </li>
          <li className="ml-8">
            Ability to self-direct the planning and scoping phase of a new project
          </li>
          <li className="ml-8">
            Strategic understanding of the timing and rationale for design choices within the scope of work
          </li>
          <li className="ml-8">
            Experience with our tech stack, which includes but is not limited to: React, Gatsby, Next, Node, GraphQL, PostgreSQL, Python, Flask, FastAPI
          </li>
        </ul>

        <h4 className="text-[#5B23A4] font-bold mt-12">
          Brownie points if you are:
        </h4>

        <ul className="mt-6 list-disc">
          <li className="ml-8">
            Eager to find opportunities to improve our engineering processes
          </li>
          <li className="ml-8">
            Willing to immediately jump in and solve problems
          </li>
        </ul>
      </div>
    </div>
  );
};

export default EngineeringPosition;
