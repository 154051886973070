import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const CareersHero = () => {
  const { placeholderImageDesktop, placeholderImageMobile } = useStaticQuery(graphql`
    query {
      placeholderImageDesktop: file(relativePath: { eq: "careers-hero-desktop.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      placeholderImageMobile: file(relativePath: { eq: "careers-hero-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 800
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  // Desktop splash image
  const imageDesktop = getImage(placeholderImageDesktop);
  const bgImageDesktop = convertToBgImage(imageDesktop);

  // Mobile splash image
  const imageMobile = getImage(placeholderImageMobile);
  const bgImageMobile = convertToBgImage(imageMobile);

  return (
    <>
      {/* Desktop */}
      <div className="hidden md:block">
        <BackgroundImage
          Tag="section"
          style={{
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "0",
            paddingTop: "31.63%",
          }}
          {...bgImageDesktop}
          preserveStackingContext
        >
          <div
            style={{
              position: "absolute",
              marginLeft: "auto",
              marginRight: "auto",
              top: "22%",
              left: 0,
              right: 0,
              textAlign: "center",
            }}
          >
            <h1 className="text-[#3C0F76] text-4xl lg:text-4xl xl:text-5xl 2xl:text-6xl tracking-wide font-bold leading-normal lg:leading-relaxed xl:leading-relaxed 2xl:leading-relaxed font-serif">
              Help Us Bring Ideas to Life
            </h1>

            <p className="mt-12 lg:mt-20 xl:mt-24 2xl:mt-32">
              <Link
                to="#open-positions"
                className="uppercase text-lg bg-[#FB3640] border-none rounded-full text-base px-12 py-4 font-semibold text-white hover:bg-[#E13039] hover:text-white"
              >
                See Career Opportunities
              </Link>
            </p>
          </div>
        </BackgroundImage>

        <div className="py-24 px-8 w-4/6 lg:w-1/2 xl:w-2/5 mx-auto">
          <p className="font-light text-lg leading-8">
            At Hillside Lab, we are all about innovation, creativity, strategy, and balance. We believe it is important to apply your skills, share your creativity, and think outside of the box in order to help bring ideas to life.
          </p>

          <p className="font-light text-lg leading-8 mt-12">
            We automate manual processes, bring efficiency, and faster growth to companies. Being a company founded on working smarter, not harder, we thrive on finding effective and efficient ways to get the job done and deliver high quality products.
          </p>

          <p className="font-light text-lg leading-8 mt-12">
            If any of this resonates with you, we invite you to check out our career opportunities and see if working with Hillside Lab might be a good fit.
          </p>
        </div>
      </div>

      {/* Mobile */}
      <div className="md:hidden">
        <BackgroundImage
          Tag="section"
          style={{
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "0",
            paddingTop: "119.86%",
          }}
          {...bgImageMobile}
          preserveStackingContext
        >
          <div
            style={{
              position: "absolute",
              marginLeft: "auto",
              marginRight: "auto",
              top: "50%",
              left: 0,
              right: 0,
              textAlign: "center",
            }}
            className="text-center"
          >
            <h1 className="text-3xl leading-relaxed tracking-wide font-serif">
              Help Us Bring
              <br />
              Ideas to Life
            </h1>

            <p className="mt-8">
              <Link
                to="#open-positions"
                className="uppercase bg-[#FB3640] border-none rounded-full text-base px-8 py-2 text-white hover:bg-[#E13039] hover:text-white"
              >
                See Career Opportunities
              </Link>
            </p>
          </div>
        </BackgroundImage>

        <div className="py-12 px-8">
          <p className="font-light leading-8">
            At Hillside Lab, we are all about innovation, creativity, strategy, and balance. We believe it is important to apply your skills, share your creativity, and think outside of the box in order to help bring ideas to life.
          </p>

          <p className="font-light leading-8 mt-6">
            We automate manual processes, bring efficiency, and faster growth to companies. Being a company founded on working smarter, not harder, we thrive on finding effective and efficient ways to get the job done and deliver high quality products.
          </p>

          <p className="font-light leading-8 mt-6">
            If any of this resonates with you, we invite you to check out our career opportunities and see if working with Hillside Lab might be a good fit.
          </p>
        </div>
      </div>
    </>
  );
};

export default CareersHero;
