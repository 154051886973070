import React from 'react';

import CareersHero from './CareersHero';
// import CareersPerks from './CareersPerks';
// import HiringProcess from './HiringProcess';
// import WhatToExpect from './WhatToExpect';
import OpenPositions from './OpenPositions';

const Careers = () => {
  return (
    <>
      <CareersHero />
      {/* <CareersPerks /> */}
      {/* <HiringProcess /> */}
      {/* <WhatToExpect /> */}

      <hr />

      <OpenPositions />
    </>
  );
};

export default Careers;
