import React from 'react';

import Layout from '../components/Layout';
import Careers from '../components/Careers';
import SEO from '../components/SEO';

const CareersPage = () => {
  return (
    <Layout>
      <SEO title="Careers" />
      <Careers />
    </Layout>
  );
};

export default CareersPage;
