import React, { useState } from 'react';

import EngineeringPosition from './EngineeringPosition';
import DesignPosition from './DesignPosition';
import SalesPosition from './SalesPosition';

const OpenPositions = () => {
  const [position, setPosition] = useState('engineering');

  const handleChange = (e) => {
    setPosition(e.target.value);
  };

  const renderPosition = () => {
    switch (position) {
      case 'engineering':
        return <EngineeringPosition />;
      case 'design':
        return <DesignPosition />;
      case 'sales':
        return <SalesPosition />;
      default:
        return <EngineeringPosition />;
    }
  };

  return (
    <div id="open-positions" className="my-12 md:my-32 md:w-2/3 md:mx-auto">
      <h2 className="text-4xl lg:text-6xl tracking-wider font-serif text-center">
        Open Positions
      </h2>
      <div className="w-2/3 mx-auto mt-6 lg:hidden">
        <select
          className="w-full h-10 px-3 rounded-lg border"
          aria-label="In page navigation"
          value={position}
          onChange={handleChange}
        >
          <option
            value="engineering"
          >
            Engineering
          </option>
          <option
            value="design"
          >
            Design
          </option>
          <option
            value="sales"
          >
            Sales
          </option>
        </select>
      </div>

      <div className="lg:grid lg:grid-flow-col lg:my-24">
        <div className="lg:col-span-4 lg:pl-8">
          {renderPosition()}
        </div>

        <div className="hidden lg:block lg:col-span-2">
          <div className="border-l pl-8 mt-12">
            <ul className="text-left text-lg">
              <li
                className={`hover:cursor-pointer ${position === 'engineering' ? 'font-bold' : ''}`}
                onClick={() => setPosition('engineering')}
              >
                Engineering
              </li>
              <li
                className={`mt-6 hover:cursor-pointer ${position === 'design' ? 'font-bold' : ''}`}
                onClick={() => setPosition('design')}
              >
                Design
              </li>
              <li
                className={`mt-6 hover:cursor-pointer ${position === 'sales' ? 'font-bold' : ''}`}
                onClick={() => setPosition('sales')}
              >
                Sales
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenPositions;
