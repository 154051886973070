import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const DesignPosition = () => {
  return (
    <div className="my-8 px-8">
      <h3 className="text-2xl font-light lg:text-4xl lg:mb-16 lg:font-normal">
        Graphic and Web Design
      </h3>

      <div className="px-8 lg:hidden">
        <StaticImage
          className="mt-8"
          src="../images/competitive-edge.png"
          alt="Graphic and web design"
        />
      </div>

      <div className="font-light text-lg">
        <p className="mt-6">
          We are looking for highly motivated and skilled Graphic/Web Designers who are looking to work on multiple projects in a dynamic environment.
        </p>

        <h4 className="text-[#5B23A4] font-bold mt-12">
          What you'll do:
        </h4>

        <p className="mt-6">
          Develop, design, and optimize visual imagery. Bring unique designs to life in order to enhance customer experience. Work alongside Engineers ensuring there is an understanding of requirements and properly translate into design. Troubleshoot issues affecting development and production environments, as well as research and create digital solutions to identify, test, and apply to current and future projects.
        </p>

        <div>
          <div className="hidden lg:w-1/3 lg:ml-6 lg:inline lg:float-right">
            <StaticImage
              src="../images/competitive-edge.png"
              alt="Graphic and web design"
            />
          </div>

          <h5 className="font-semibold mt-6">
            Initially, you'll help:
          </h5>

          <ul className="mt-6 list-disc">
            <li className="ml-8">
              Create design comps in Figma
            </li>
            <li className="ml-8">
              Design UI/UX for new product features
            </li>
          </ul>

          <h5 className="font-semibold mt-6">
            Also, you'll be challenged to:
          </h5>

          <ul className="mt-6 list-disc">
            <li className="ml-8">
              Create working prototypes
            </li>
            <li className="ml-8">
              Level-up existing designs with animation and interactivity
            </li>
          </ul>

        </div>

        <h4 className="text-[#5B23A4] font-bold mt-12">
          What we are looking for:
        </h4>

        <ul className="mt-6 list-disc">
          <li className="ml-8">
            Foundational knowledge of HTML, CSS, and Javascript
          </li>
          <li className="ml-8">
            Experience with graphic design, digital design, and animation
          </li>
          <li className="ml-8">
            Ability to translate requirements to design
          </li>
          <li className="ml-8">
            Understanding of software development lifecycles, web technologies, web development, mobile development, and design processes
          </li>
          <li className="ml-8">
            Experience with GCP or AWS a plus
          </li>
          <li className="ml-8">
            Experience with collaborative design tools like Sketch and Figma
          </li>
        </ul>

        <h4 className="text-[#5B23A4] font-bold mt-12">
          Brownie points if you are:
        </h4>

        <ul className="mt-6 list-disc">
          <li className="ml-8">
            Exerienced in front-end development
          </li>
          <li className="ml-8">
            Familiar with animation concepts and software
          </li>
          <li className="ml-8">
            Capable of addressing challenges by thinking outside of the box
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DesignPosition;
