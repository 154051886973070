import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const SalesPosition = () => {
  return (
    <div className="my-8 px-8">
      <h3 className="text-2xl font-light lg:text-4xl lg:mb-16 lg:font-normal">
        Sales
      </h3>

      <div className="px-8 lg:hidden">
        <StaticImage
          className="mt-8"
          src="../images/launch-new-products.png"
          alt="Sales"
        />
      </div>

      <div className="font-light text-lg">
        <p className="mt-6">
          We are looking for a highly motivated and skilled Sales Lead who is looking to work on multiple projects in a dynamic environment.
        </p>

        <h4 className="text-[#5B23A4] font-bold mt-12">
          What you'll do:
        </h4>

        <p className="mt-6">
          Own the sales process and outcomes, lead sales strategy investing time in established and new accounts. Spend time developing relationships with key buyers to be their main point of contact.
        </p>

        <div>
          <div className="hidden lg:w-1/3 lg:ml-6 lg:inline lg:float-right">
            <StaticImage
              src="../images/launch-new-products.png"
              alt="Sales"
            />
          </div>

          <h5 className="font-semibold mt-6">
            What we are looking for:
          </h5>

          <ul className="mt-6 list-disc">
            <li className="ml-8">
              Experience in the technology industry
            </li>
            <li className="ml-8">
              Ability to close new business/clients by addressing prospects' technical challenges
            </li>
            <li className="ml-8">
              Understanding of end-to-end marketing solutions, from design to technology, to optimization
            </li>
            <li className="ml-8">
              Ability to absorb complex technical concepts and communicate them to a non-technical audience effectively
            </li>
            <li className="ml-8">
              Ability to think creatively, sell strategically, and communicate efficiently both internally and externally
            </li>
          </ul>
        </div>

        <h4 className="text-[#5B23A4] font-bold mt-12">
          Brownie points if you:
        </h4>

        <ul className="mt-6 list-disc">
          <li className="ml-8">
            Are able to put yourself out there in order to gain new clients
          </li>
          <li className="ml-8">
            Willingness to immediately jump in and solve problems
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SalesPosition;
